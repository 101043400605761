import React from 'react';
import NewMainHeader from './NewMainHeader';
import Prospect from './Prospect';
import Footer from './Footer';
import MobileFooter from './MobileFooter';
import CarouselCards2 from './CarouselCards2';
import CancerCarouselCards3 from './CancerCarouselCards3';
import StickyContactMenu from './StickyContactMenu';
import FamilyPlanningVideo from './videos/FamilyPlanningVideo';
import MetaTags from 'react-meta-tags';



class FamilyPlanning extends React.Component {
   
    toBookSession(){
      window.location.href="/book-counselling"
    }
    toOrder(){
      window.location.href="/product-page"
    }
    toGenetic(){
      window.location.href="/genetic-counselling"
    }
    render() {
return(<div>
    <meta charSet="utf-8" />

    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossOrigin="anonymous" />

    <link rel="icon" href="./img/Logo.png" type="image/gif" sizes="16x16" />
    <link rel="stylesheet" href="./css/header_footer.css" />
    <link rel="stylesheet" href="./css/stylesheet_custom.css" />
    <link rel="stylesheet" href="stylesheet_custom.css" />
    <link rel="stylesheet" href="./css/fonts.css" />


    <style dangerouslySetInnerHTML={{__html: "\n.newww{\n color: rgb(79, 91, 231)!important; font-weight:400;\n}\n.career-banner-sec-wrap .career-banner-sec .container p{\n  font-size: 1.5rem;\n    padding-bottom: 1px;\n    color: #2c333c;\n    font-weight: 300;\n    max-width: 640px;\n    line-height: 30px;\n}\n.custom-size {\n  height:450px!important;\n}\n.careerdisease-content{\n  font-family: 'Roboto', sans-serif;\n    font-size: 1.125rem;\n    font-weight: 400;\n    text-align: left;\n    padding-top: 15px;\n    margin: 0;\n}\n.carriercard .card-text\n{\nfont-size:1.125rem;\nfont-weight:400;\n}\n.font-nav{\n  padding-right:15px;\nfont-size:15px;\ncolor:gray;\n/* font-weight:300; */\n\n}\n\n.img-11{\n  height:48px;\n  /* width:120px; */\n}\n.order-btn, .she-btn{\n  padding-top: 5px;\n}.nav-tab-1{\n    padding-top:5px!important;\n    padding-bottom:5px!important;\n    color:#808080;\n    font-size:15px;\n\n}\n@media (max-width: 767.98px) {\n  .career-banner-sec-wrap .career-banner-sec .container p{\n    font-size: 14px;\n          padding-bottom: 1px;\n      color: #2c333c;\n      font-weight: 300;\n      max-width: 640px;\n      /* line-height: 14px; */\n  }\n  .font-nav{\n    padding-right:4px;\n  font-size:10px;\n  color:gray;\n\n  }\n  .nav-tab-1{\n    /* margin-bottom: :16rem; */\n      padding-top:3px!important;\n      padding-bottom:3px!important;\n      color:#808080;\n\n  }\n   }\n   .nav-active1{\n     font-weight:700;\n     color:#4f5be7;\n   }\n\n   @media only screen and (max-width: 576px){\n.career-banner-sec-wrap .career-banner-sec .container {\n    /* height: 40vh; */\n    /* margin-left: 0!important; */\n        /* padding-top: 0px!important; */\n}\n}\n" }} />
    <link rel="stylesheet" href="./css/sticky.css" />
    <link rel="stylesheet" href="./style-mobile2.css" />
    <link rel="stylesheet" href="./style-mobile.css" />
    <link rel="stylesheet" href="newsticky.css" />
    <MetaTags>
    <title>Genetic counselling for family planning (for Parents) - Trugeny</title>
		<meta name="description" content="Genetic counselling for family planning."/>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta property="og:title" content="Genetic counselling for family planning (for Parents) - Trugeny"/>
        <meta property="og:site_name" content="truGeny"/>
        <meta property="og:url" content="https://trugeny.com/"/>
        <meta property="og:description" content="Genetic counselling for family planning."/>
        <meta property="og:type" content="website"/>
        <meta property="og:image" content=""/>
        <meta name="keywords" content="Genetic counselling for family planning, Prenatal Genetic Counseling, genetic counseling family planning, cost of genetic counseling family planning"/>
        <meta property="og:keywords" content="Genetic counselling for family planning, Prenatal Genetic Counseling, genetic counseling family planning, cost of genetic counseling family planning"/>



    </MetaTags>
    <NewMainHeader category="familyPlanning"></NewMainHeader>
    <div class="banner_familyplanning2">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                  <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                      <div class="carousel-inner">
                        <div class="carousel-item active">
                            <h1 class="plan-future">Plan For Your Future<br/>
                              Children Better! </h1>
                        </div>
                        <div class="carousel-item">
                          <h1 class="plan-future">Plan For Your Future<br/>
                              Children Better2! </h1>
                        </div>
                        <div class="carousel-item">
                          <h1 class="plan-future">Plan For Your Future<br/>
                              Children Better3! </h1>
                        </div>
                      </div>
                      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      </button>
                      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="familyplanning1 shadow home_new1">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="familyplanning2 pt-5 px-5">Learn what your genes say about your risk of passing on certain conditions to your child. It’s simple but very powerful.</p>
            <div class="text-center mb-5">
              <a style={{color:"white",cursor:"pointer"}} onClick={this.toGenetic} role="button" class="familyplanning3  btn-primary">LEARN NOW</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container home_new2">
      <div class="row">
        <div class="col-12">
          <p class="familyplanning2 pt-5">Learn what your genes say about your risk of passing on certain conditions to your child. It’s simple but very powerful.</p>
          <div class="text-center mb-5">
            <a style={{color:"white",cursor:"pointer"}} onClick={this.toGenetic}  role="button" class="familyplanning3  btn-primary">LEARN NOW</a>
          </div>
        </div>
      </div>
    </div>
    <div class="container ">
      <div class="row">
        <div class="col-sm-12 py-5 text-center">
          <p class="familyplanning4">Thinking of starting a family?</p>
        </div>
      </div>
    </div>

    

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 mt-5 px-0">
          <div class="familyplanning27 p-5">
            <p class="familyplanning7 familyplanning8 familyplanning13">How important is protecting the health of your future family? <br/><br/>
              How often do you as a couple hope for a family that's healthy?<br/><br/>                
              Do you worry about what health conditions you might pass on to your future child?</p>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <img class="img-fluid" src="./img/newpages/Component 6 – 1.png"/>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12 py-5 text-center">
          <p class="familyplanning4">A lack of clarity may not only be a worry,<br/>
              but also a risk for your future family!</p>
       
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="">
        <div class="row py-5">
          <div class="col-sm-5 text-center" style={{zIndex: '5'}}>
            <img class="img-fluid5 y" src="./img/newpages/Illustration3.png"/>
          </div>
          <div class="col-sm-7 px-0">
            <div class="familyplanning11 p-5">
              <p class="familyplanning7 familyplanning13 pl-5 familyplanning14 familyplanning28">truGeny helps couples to learn and assess if there are any genetic risks they carry which could possibly be passed on to their children.<br/><br/> 
                  With expert guidance and advanced testing, truGeny makes it easy for couples to plan better for a healthier family. </p>
            </div>
          </div>
        </div>
      </div>
    </div>



    <div class="container">
      <div class="row">
        <div class="col-sm-12 py-5 text-center">
          <p class="familyplanning4 pb-4">Watch to learn and plan for your future family!</p>
          <iframe class="iframevieo" src="https://www.youtube.com/embed/TGWIGNSgdIA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          <div class=" mb-5 mt-5">
              <a style={{color:"white",cursor:"pointer"}} onClick={this.toBookSession}  role="button" class="familyplanning3  btn-primary">SCHEDULE MY GENETIC COUNSELLING APPOINTMENT</a>
            </div>
          <p class="familyplanning4 py-5">I want learn about my risks of passing on genetic<br/>
              disorders to my future children</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 text-center">
          <img class="img-fluid" src="./img/newpages/benefitIllustration.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">Schedule my genetic<br/>
              counselling appointment</p>
          <div class=" mb-5 mt-5">
            <a style={{color:"white",cursor:"pointer"}} onClick={this.toBookSession}  role="button" class="familyplanning18  btn-primary">Schedule Now</a>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <img class="img-fluid" src="./img/newpages/Box22.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">Order my truFamily test kit<br/><br/></p>
          <div class=" mb-5 mt-5">
            <a style={{color:"white",cursor:"pointer"}} onClick={this.toOrder}  role="button" class="familyplanning18  btn-primary">Order Now</a>
          </div>
        </div>
      </div>
      <div class="row">
          <div class="col-sm-12 py-5 text-center">
            <p class="familyplanning4 pb-4">We inherit genes from our parents!</p>
          </div>
        </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-6 mt-5 px-0">
          <div class="familyplanning29 p-5">
            <p class="familyplanning7 familyplanning8 familyplanning13">Our DNA contains two copies of every gene - one inherited from the mother and one from the father. Genes are respomsible for passing along family characteristics like hair, eye color, height and so on.<br/><br/>                
              Sometimes they also pass on inherited conditions such as thalassemia, cystic fibrosis, sickle cell anaemia, spinal muscular atrophy and so on .</p>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <img class="img-fluid" src="./img/newpages/cancerpage-sec-2-img.png"/>
        </div>
      </div>
    </div>
    
    <div class="">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <p class="familyplanning4 py-5">A carrier has a 50% chance of passing<br/>
              on the same mutation to the child.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 home_new3">
            <img class="img-fluid familyplanning21 " src="./img/newpages/Illustration33.png"/>
          </div>
          <div class="col-sm-6 mt-4">
              <p class="familyplanning2 familyplanning13"><strong>SYMPTOMS</strong> </p>
              <p class="familyplanning7 familyplanning13  familyplanning30">It can be worrisome for couples planning to have a baby, because a carrier doesn’t necessarily know if he or she carries a genetic condition, as there may be no symptoms of the disease.</p>     
          </div>
        </div>
        <div class="row py-5">
          <div class="col-12 text-center">
              <p class="familyplanning4">What is a Carrier ? </p>
              <img class="img-fluid" src="./img/newpages/Mask Group 10.png"/>
          </div>
        </div> 
        <div class="row">
          <div class="col-sm-6 mt-4">
              <p class="familyplanning2 familyplanning13"><strong>CARRIER</strong> </p>
              <p class="familyplanning7 familyplanning13  familyplanning30">A carrier is an individual who carries a genetic mutation associated with a disease. While the individual may not display disease symptoms and is therefore unaware that they have a gene for a disorder, he or she may pass on this mutation to their children.</p>  
              <p class="familyplanning7 familyplanning13  "><strong>An abnormal change in a gene is known as a mutation.</strong></p>   
          </div>
          <div class="col-sm-6 home_new3">
              <img class="img-fluid familyplanning21" src="./img/newpages/Illustration22222.png"/>
          </div>
        </div>
        <div class="row py-5 my-5">
          <div class="col-12 text-center">
              <a style={{color:"white",cursor:"pointer"}} onClick={this.toBookSession}  role="button" class="familyplanning3  btn-primary">SCHEDULE MY GENETIC COUNSELLING APPOINTMENT</a>
          </div>
        </div> 
      </div>
    </div>

    <div class="familyplanning31 mt-5">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center">
            <p class="familyplanning4 pt-5">Be Proactive</p>
            <p class="familyplanning9 familyplanning10 pb-5"><strong>Know your risk to take ACTION.</strong><br/><br/> It’s important to KNOW. <br/><br/>    As a prospective parent, learning about your risk of passing on a genetic disease to your future offspring before pregnancy will provide you more time with wider range of options to make an informed decision.         </p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6 text-center">
            <img class="img-fluid" src="./img/newpages/Box.png"/>
          </div>
          <div class="col-sm-6 ">
            <div class="row pt-4">
              <div class="col-2  text-center"><img class="img-fluid" src="./img/newpages/Group 87.png"/></div>
              <div class="col-10 ">
                <p class="familyplanning6 familyplanning13">truFamily</p>
                <p class="familyplanning7 familyplanning13">Carrier Screening Test</p>
              </div>
            </div>
            <p class="familyplanning7 familyplanning13 familyplanning20">truFamily Carrier screening test will help you and your spouse
              find out if you are a carrier for certain inherited conditions</p>
               
          </div>
        </div>
        <div class="row py-5">
          <div class="col-12 text-center">
              <p class="familyplanning9  pb-3">I want to learn More</p>  
            <a style={{color:"white",cursor:"pointer"}} onClick={this.toBookSession}  role="button"  class="familyplanning3  btn-primary" >SCHEDULE MY GENETIC COUNSELLING APPOINTMENT</a>
          </div>
        </div> 
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12 py-5 text-center">
          <p class="familyplanning4">Who will benefit from truFamily Carrier Screening Test?</p>
          
        </div>
      </div>
      <div class="row py-5">
        <div class="col-sm-3 text-center">
          <img class="img-fluid5 familyplanning21" src="./img/newpages/Group 215.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">Starting a Family</p>
        </div>
        <div class="col-sm-3 text-center">
          <img class="img-fluid5 familyplanning21" src="./img/newpages/Group 216.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">Increased Risk </p>
        </div>
        <div class="col-sm-3 text-center">
          <img class="img-fluid5 familyplanning21" src="./img/newpages/Group 217.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">Prior History</p>
        </div>
        <div class="col-sm-3 text-center">
          <img class="img-fluid5 familyplanning21" src="./img/newpages/Group 218.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">Consanguineous Marriage</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 py-5 ">
          <p class="familyplanning4 mb-5 text-center">truFamily carrier screening helps screen your genes<br/>
              to see if you are a carrier for over 250 genetic conditions </p>
          <div class="row">
              <div class="col-sm-6 mt-4">
                  <p class="familyplanning2 familyplanning13"><strong>Benefits of truGeny &<br/>Reliability of our test</strong> </p>
                  <p class="familyplanning7 familyplanning13  familyplanning30">Conversation FIRST - Our expert genetic counsellor will assess your family history and risk factors, and determine if genetic testing is right for you and ensure that you are receiving the most appropriate test.</p>  
              </div>
              <div class="col-sm-6 ">
                  <img class="img-fluid familyplanning21" src="./img/newpages/Vector_Smart_Objectnew.png"/>
              </div>
          </div>
        </div>
      </div>

      <div class="row py-5">
          <div class="col-sm-6 ">
              <img class="img-fluid familyplanning21" src="./img/newpages/Illustration-7.png"/>
          </div>
          <div class="col-sm-6 mt-4">
              <div class="row">
                  <div class="col-2">
                  </div>
                  <div class="col-10">
                      <p class="familyplanning2 familyplanning13"><strong>A highly reliable test.</strong> </p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-2">
                    <img src="./img/newpages/7-18-16.png" class="img-fluid"/>
                  </div>
                  <div class="col-10">
                      <p class="familyplanning15 familyplanning13 familyplanning7 pt-3" sty="">We don’t just look at snippets of your DNA. We do a thorough analysis of your genes.</p>
                  </div>
              </div>
              <div class="row">
                  <div class="col-2">
                    <img src="./img/newpages/7-18-18.png" class="img-fluid"/>
                  </div>
                  <div class="col-10">
                      <p class="familyplanning15 familyplanning13 familyplanning7 pt-3" sty="">Our reports are actionable that can be shared with your Doctor for further planning</p>
                  </div>
              </div>
          </div>
      </div>
      
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12 py-5 text-center">
          <p class="familyplanning4 py-5">I want learn about my risks of passing on genetic<br/>
              disorders to my future children</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 text-center">
          <img class="img-fluid" src="./img/newpages/benefitIllustration.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">Schedule my genetic<br/>
              counselling appointment</p>
          <div class=" mb-5 mt-5">
            <a style={{color:"white",cursor:"pointer"}} onClick={this.toBookSession}  role="button" class="familyplanning18  btn-primary">Schedule Now</a>
          </div>
        </div>
        <div class="col-sm-6 text-center">
          <img class="img-fluid" src="./img/newpages/Box22.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">Order my truFamily test kit<br/><br/></p>
          <div class=" mb-5 mt-5">
            <a style={{color:"white",cursor:"pointer"}} onClick={this.toOrder}  role="button" class="familyplanning18  btn-primary">Order Now</a>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-sm-12 py-5 text-center">
          <p class="familyplanning4">IT'S AN EASY AND SIMPLE PROCESS</p>
          <p class="familyplanning9 familyplanning10">What do you GET?</p>
          <p class="familyplanning9 familyplanning10 pt-3">It starts with a conversation </p>
        </div>
      </div>
      <div class="row py-5">
      <div class="col-sm-3 text-center">
          <img class="img-fluid5 familyplanning21" src="./img/newpages/Group 210.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">1. SCHEDULE AN<br/>APPOINTMENT</p>
        </div>
        <div class="col-sm-3 text-center">
          <img class="img-fluid5 familyplanning21" src="./img/newpages//Group 211.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">2. INITIAL<br/> CONSULTATION</p>
        </div>
        <div class="col-sm-3 text-center">
          <img class="img-fluid5 familyplanning21" src="./img/newpages/Group 212.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">3. SAMPLE<br/>COLLECTION</p>
        </div>
        <div class="col-sm-3 text-center">
          <img class="img-fluid5 familyplanning21" src="./img/newpages/Group 210.png"/>
          <p class="familyplanning7 familyplanning13 pt-4">4. RESULTS<br/> CONSULTATION</p>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12 py-5 text-center">
          <p class="familyplanning4 mb-5">GET A 15 MIN FREE HEALTH CONSULTATION! </p>
          <a style={{color:"white",cursor:"pointer"}} onClick={this.toBookSession}  role="button" class="familyplanning3  btn-primary">BOOK MY APPOINTMENT</a>
        </div>
      </div>
    </div>

    {/* <div class="familyplanning22 py-5 mb-5">
      <div class="container">
        <div class="row py-4">
          <div class="col-12">
            <div class="familyplanning23 text-center">
              <p class="familyplanning6 mb-0 py-5 ">Get your health risk score <img class="img-fluid" src="./img/newpages/Group 16.png"/> </p>
            </div>
          </div>
        </div>
      </div>
    </div> */}

    <Prospect></Prospect>
  <Footer></Footer>
    {/* end of footer */}


    {/* sticky form---------------- */}
    <MobileFooter></MobileFooter>

  </div>
  );

    }
}
export default FamilyPlanning;
