import React from 'react';
import {withRouter,Link} from 'react-router-dom'
import { Card, CardBody, CardTitle, CardHeader, Button, Form, FormGroup, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import axios from '../axios/axios';
import swal from 'sweetalert';

class Prospect extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
     
      errors:{},
      submitData:'',
      };
    this.prospectReg = this.prospectReg.bind(this);
   
  }
  prospect = (e) => {
    const prospectForm = this.state
    prospectForm[e.target.name] = e.target.value;
    this.setState(prospectForm);
  }
  validateForm() {

    let fields = this.state;
    let errors = {};
    let formIsValid = true;

    if (!fields["pname"]) {
      formIsValid = false;
      errors["pname"] = "*Please enter your name";
  }


    if (!fields["pemail"]) {
        formIsValid = false;
        errors["pemail"] = "*Please enter your email address";
    }

    if (typeof fields["pemail"] !== "undefined") {
        //regular expression for email validation
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(fields["pemail"])) {
            formIsValid = false;
            errors["pemail"] = "*Please enter valid email address";
        }
    }

    if (!fields["pphone"]) {
        formIsValid = false;
        errors["pphone"] = "*Please enter your mobile no.";
    }

    if (!fields["pmessage"]||fields["pmessage"]==="") {
      formIsValid = false;
      errors["pmessage"] = "*Please enter message.";
  }

    if (typeof fields["pphone"] !== "undefined") {
        if (!fields["pphone"].match(/^[0-9]{10}$/)) {
            formIsValid = false;
            errors["pphone"] = "*Please enter valid mobile no.";
        }
    }
    this.setState({
        errors: errors
    });
    return formIsValid;

}
  prospectReg(e) {
    e.preventDefault();

    if (this.validateForm()) {
      this.setState({submitData:true})
      const source = "TruGenyLead";

      const { pname, pphone, pemail, pmessage } = this.state;
      axios.post('users/prospect', { pname, pphone, pemail, pmessage, source })
        .then((response) => {

          swal("Thank you for your interest. We will get back you soon...").then(
            this.setState({
    
              errors: {},submitData:''
            })
          )
          // this.setState({
          //     sucessMsg:"Thank you for your interest. We will get back you soon..."
          // })
          // alert("Thanks for your intersr. We will get back you soon")   


        }).catch(error => {
          if (error.response === undefined) {
            this.props.history.push("/error")
          } else {
            this.setState({
              errorMsg: error.response.data.message,
             
              errors: {},submitData:''
            })
          }

        });
      e.target.reset();
    }


  }

  toRiskWelcome() {
    window.location.href="/risk_assessment";
  }
  render() {
      return (
        <>
        <div class="familyplanning22 py-5 mb-5">
    <div class="container">
      <div class="row py-4">
        <div class="col-12">
          <div class="familyplanning23 text-center">
            <a style={{color:"white",cursor:"pointer"}} onClick={this.toRiskWelcome} role="button" style={{cursor:"pointer"}}>
            <p class="familyplanning6 mb-0 py-5 ">Get your health risk score <img class="img-fluid" src="./img/newpages/Group 16.png"/> </p>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
        <div className="gc-form-card container">
        {/* <div className="gc-form-card-wrap  shadow-lg"> */}
        <div className="gc-form-card-wrap  ">

          <div className=" gc-form-card-top pt-3">
            <h2 className="text-center"> Take control of your health, NOW!</h2>
            <p className=" gc-form-subtitle text-center">All you need to do is, fill out the form below and one of our experts will reach out to you very soon. </p>
            {/* <p class="text-center" > sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> */}
          </div>{/* gc-form-card-top */}
          <div className="gc-form-card-btm1">
            <form className="gc-form needs-validation" onSubmit={this.prospectReg}>            
              <div className="form-row justify-content-center">
                <div className="gc-col  col-md-5 mb-3" htmlFor="validationTooltip01">
                <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pname}</div>
                  <div className="input-group ">
                    <input type="text"  className="form-control m-0" id="validationTooltip01" name="pname" placeholder="Name:" onChange={this.prospect}  placeholder="Name"  required />
                    <div className="valid-tooltip">
                      Looks good!
                    </div>
                    <div className="input-group-prepend border-0">
                      <span className="input-group-text border-0" id="validationTooltip01"><img src="img/homepage/i1.png" /></span>
                    </div>
                  </div>
                </div>
                  <div className="gc-col col-md-5 mb-3">
                  <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pemail}</div>
                  <div className="input-group">                  
                    <input type="email" name="pemail" onChange={this.prospect}  className="form-control m-0" id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend"  required/>
                    <div className="invalid-tooltip">
                      Please enter a valid E-mail ID.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                    </div>
                  </div>
                  <div className="input-group d-none">
                    <input type="text"  className="form-control m-0" id="validationTooltip02" placeholder="Reason" defaultValue="Reason"  />
                    <div className="valid-tooltip">
                      Looks good!
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltip02"><img src="img/homepage/i2.png" /></span>
                    </div>
                  </div>
                </div>
              </div>{/* end of form-row */}
              {/* <div className="form-row justify-content-center d-none">
                <div className=" gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input type="email" name="email" className="form-control m-0" id="validationTooltipEmailPrepend" placeholder="E-mail ID" aria-describedby="validationTooltipEmailPrepend" required />
                    <div className="invalid-tooltip">
                      Please enter a valid E-mail ID.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltipEmailPrepend"><img src="img/homepage/i3.png" /></span>
                    </div>
                  </div>
                </div>
                <div className="gc-col col-md-5 mb-3">
                  <div className="input-group">
                    <input type="text" name="refered" className="form-control m-0" id="validationTooltip03" placeholder="Referred by" defaultValue="Referred by" aria-describedby="validationTooltip03" required />
                    <div className="valid-tooltip">
                      Looks good!
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltip03"><img src="img/homepage/i4.png" /></span>
                    </div>
                  </div>
                </div>
              </div>end of form-row */}
              
              <div className="form-row justify-content-center">
                <div className="gc-col col-md-5 mb-3">
                   <div style={{ color: 'red', fontSize: '12px' }}>{this.state.errors.pphone}</div>
                  <div className="input-group">                 
                    <input className="form-control m-0" type="tel" onChange={this.prospect} id="validationTooltip05" placeholder="Phone number" name="pphone" pattern="[0-9]{10}"  required/>
                    <div className="invalid-tooltip">
                      Please provide a valid Phone number.
                    </div>
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="validationTooltip05"><img src="img/homepage/i5.png" /></span>
                    </div>
                  </div>
                </div>
                <div className="gc-col col-md-5 mb-3">
                  <select className="form-control roboto-reg-16px" id="exampleFormControlSelect1" placeholder="Select an option"  name="pmessage" onChange={this.prospect} style={{ height: '60px', borderColor: this.state.errors.pmessage ? "#ff0000" : "" }}required >
                    <option className="dropdown-item roboto-reg-16px" value="" >I’m interested to learn about.</option>
                    <option className="dropdown-item roboto-reg-16px" value="Pregnancy">Pregnancy</option>
                    <option className="dropdown-item roboto-reg-16px" value="My risk for Cancer">My risk for Cancer</option>
                    <option className="dropdown-item roboto-reg-16px" value="My risk for heart disease">My risk for heart disease</option>
                  </select>
                  {/* <div className="input-group d-none">
                    <textarea name="message" className="form-control m-0 " id="validationTextarea" rows={1} placeholder="Message"  defaultValue={""} />
                  </div> */}
                </div>
              </div>{/* end of form-row */}
              <div className="wrapper pt-3 mt-4 ">
                <button className="center-block btn btn-outline-primary btn-hover-white my-2 my-sm-0 center" style={{height: '60px', width: '210px', borderRadius: '30px', color: 'white', background: '#4f5be7'}} name="submit" type="submit" disabled={this.state.submitData?true:false}><b>SUBMIT</b></button>
              </div>
            </form>
          </div>{/* end of gc-form-card-btm */}
        </div>{/* end of gc-form-card-wrap */}
      </div>
</>
        
//   <div class="container">
//   <div class="row py-5">
//     <div class="col-12 text-center">
//       <p class="familyplanning4 mb-4">Take control of your health, NOW! </p>
//       <p class="familyplanning9 familyplanning10">All you need to do is, fill out the form below and one<br/>
//         of our experts will reach out to you very soon.</p>
//     </div>
//   </div>
//   <div class="row">
//     <div class="col-12 familyplanning26">
//       <form>
//         <div class="row">
//           <div class="col-sm-6 px-4">
//             <div class="input-group flex-nowrap familyplanning24 my-4">
//               <input type="text" class="form-control border-0 bg-none familyplanning25" placeholder="Username" aria-label="Name" aria-describedby="addon-wrapping"/>
//               <span class="input-group-text border-0 bg-none familyplanning25"  id="addon-wrapping" style={{background:'transparent'}}><img class="img-fluid" src="./img/newpages/Iconly-Light-Outline-Add-User.png"/></span>
//             </div>
//             <div class="input-group flex-nowrap familyplanning24 my-4">
//               <input type="phone" class="form-control border-0 bg-none familyplanning25" placeholder="Phone number" aria-label="Name" aria-describedby="addon-wrapping"/>
//               <span class="input-group-text border-0 bg-none familyplanning25"  id="addon-wrapping" style={{background:'transparent'}}><img class="img-fluid" src="./img/newpages/phone-plus.png"/></span>
//             </div>
//           </div>
//           <div class="col-sm-6 px-4">
//             <div class="input-group flex-nowrap familyplanning24 my-4">
//               <input type="email" class="form-control border-0 bg-none familyplanning25" placeholder="E-mail ID" aria-label="Name" aria-describedby="addon-wrapping"/>
//               <span class="input-group-text border-0 bg-none familyplanning25"  id="addon-wrapping" style={{background:'transparent'}}><img class="img-fluid" src="./img/newpages/mail-bulk.png"/></span>
//             </div>
//             <div class="input-group flex-nowrap familyplanning24 my-4">
//               <input type="text" class="form-control border-0 bg-none familyplanning25" placeholder="I'm interested to learn about" aria-label="Name" aria-describedby="addon-wrapping"/>
//               <span class="input-group-text border-0 bg-none familyplanning25"  id="addon-wrapping" style={{background:'transparent'}}><img class="img-fluid" src="./img/newpages/Iconly-Light-Outline-Arrow---Down-2.png"/></span>
//             </div>
//           </div>
//         </div>

//         <div class="row">
//           <div class="col-12 text-center my-3">
//             <a href="" class="familyplanning3  btn-primary" type="submit">Submit</a>
//           </div>
//         </div>
//       </form>
//     </div>
//   </div>
// </div>
      );
  }
    }
    


export default withRouter(Prospect);
