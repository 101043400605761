import React from 'react';
import Cookies from 'js-cookie';
import *as PayContants from './paymentContants';

import { getJwt } from './jwt';

class NewMainHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      menu: false,
      userActive:'',
      prevScrollpos: window.pageYOffset,
      visible: false,
      desktop:false,
      mobilemenu:false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.resize = this.resize.bind(this);
    this.toggleMobileMenu =this.toggleMobileMenu.bind(this);
    this.toggle = this.toggle.bind(this);

  }
  componentDidMount() {
    // const jwt=getJwt();

  //   if(jwt){
  //   if(Cookies.get("jwt-token")===""||Cookies.get("jwt-token")===null||Cookies.get("jwt-token")===undefined){
     
  //     swal("Your your session has expired")
  //     this.props.history.push("/logout");

  //   }
  // }
    if(Cookies.get("userId")){
      this.setState({userActive:"active"}, function () {
      });
    }
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

  };

  toggle() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen },()=>{})

}
toOrder(){
  window.location.href="/product-page"
}

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);

  };
  resize() {
   const desktop= window.innerWidth>=576;
   this.setState({desktop},()=>{});
}
  toggleMenu() {
    this.setState({ menu: !this.state.menu },()=>{})
  }
  toggleMobileMenu() {
    this.setState({ mobilemenu: !this.state.mobilemenu },()=>{})
  }

  dashBoard(){
    window.location.href="/loginSuccess";

  }
  toHome(){
    window.location.href="/"
  }

  handleScroll = () => {
    const { prevScrollpos } = this.state;

    const currentScrollPos = window.pageYOffset;

    const visible = prevScrollpos > 50;

    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    },()=>{});
  };


  render() {
    const show = (this.state.menu) ? "show" : "";
    const dropdownOpen =(this.state.dropdownOpen) ? "show" : "";

    const showheader = this.props.category
    // if (showheader === "about") {
    //   this.state.visible = false
    // }
    if (showheader === "foryou") {
      this.state.submenu = false
    }
    if (showheader === "genetic-counselling" || showheader === "cancer" || showheader === "heart"|| showheader==="familyPlanning"||showheader==="product-page-index") {

      this.state.submenu = false
    }
    if (showheader === "HelpIndivisual" || showheader === "help" || showheader === "helpClinicians" || showheader === "helpCounselling" || showheader === "geneticCounselling" || showheader === "helpSecurity") {
      this.state.helpmenu = true
    }
    let styles;
    let contactstyles;
    if(this.state.mobilemenu){
      styles = {
        sideBar1: {
          width: '250px',
        },
        bgwhit:{
          'margin-left': '250px'
        }


      };
    }else{
      styles = {
        sideBar1: {
          width: '0px',
        },
        bgwhit:{
          'marginLeft': '0px'
        }


      };

    }


    const { sideBar1,bgwhit } = styles;
    let you;
    if(this.props.category==="foryou"){
      you=
      <div >
{/* 
      <h1 className="text-center banner-text-h1 mx-auto">Your DNA is not your Destiny</h1>
      <div className="mainContent">
      <h4 className="text-center banner-text-h4-1 mx-auto">Almost all diseases have a genetic component to them.<br/> Learn what your genes say about your risk for developing certain diseases.</h4>
      </div>
      <h4 className="text-center  text-black banner-text-h4-2 mx-auto" style={{fontWeight: 700}}>Take control of your health destiny.</h4>

      <div className="space" />
      <div id="section-1" className="d-flex justify-content-center">
        <a style={{scrollBehavior: '[ auto | smooth ]'}} href="#section-1">  <img className="img-fluid d-none d-md-block d-lg-block btn-hover-white1" src="./img/111.png" /></a>
      </div> */}
      </div>

    }
    return (

      <div >
      <div id="mySidebar" className="sidebar1" style={sideBar1}>
      {Cookies.get("username")?
        <div className="signin-register">
      <ul>
        <li><a style={{ color:  'green' , fontWeight:  'bold'  }} className="signinRegisterBtn" id="signin" href="/loginSuccess">{Cookies.get("username")}</a></li>
      </ul>
    </div>
           : <div className="signin-register pb-0">
              <ul>
                <li><a style={{ color: showheader === "signin" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "signin" ? 'bold' : '' }} className="signinRegisterBtn" id="signin" href="/login" >Sign in</a> <span>|</span> <a className="signinRegisterBtn" id="register" style={{ color: showheader === "register" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "register" ? 'bold' : '' }} href="/register">Register</a></li><hr />
              </ul>
            </div>
      }
            <a  className="closebtn" onClick={this.toggleMobileMenu}>×</a>
            <ul className="main-navbar-nav navbar-nav">
            {Cookies.get("username")?
             <li className="nav-item">
              <hr />
                <a href="/loginSuccess" className="nav-link m-2 menu-item ">My DashBoard</a>
                <hr />
              </li>:""}
              <li className="nav-item">
              <div className="book-button my-0">
                  <button type="button" className="my-0 btn btn-primary rounded-pill" style={{border:'2px solid red'}}>
                  <a href="/help_covid">Coronavirus HELP</a>
                  </button>
                </div>
    </li>

              <li className="nav-item">
                <a href="/about-us" className={this.props.category === "about" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>About</a>
                <hr />
              </li>
              <li className="nav-item">
              <a href="/"  className={this.props.category === "foryou" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>{Cookies.get("username")?"Discover":"For you"}</a>
              </li>

              <li className="nav-item">
                <a className={this.props.category === "genetic-counselling" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "} href="/genetic-counselling">Genetic Counselling</a>
              </li>
              <li className="nav-item">
                <a className={this.props.category === "cancer" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "} href="/genetic-testing-cancer-risk">Cancer Health</a>
              </li>
              <li className="nav-item">
                <a className={this.props.category === "heart" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "} href="/genetic-testing-heart-disease">Heart Health</a>
                <hr />
              </li>
              <li className="nav-item">
                <a className={this.props.category === "familyPlanning" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "} href="/family-planning">Family Planning</a>
                <hr />
              </li>
              {/* <li class="nav-item">
        <a href="clinicians.html" class="nav-link m-2 menu-item">for clinicians</a>
      </li> */}
      {Cookies.get("username")?"":
      <li className="nav-item">
      <a href="/clinicians" className={this.props.category === "clinicians" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>For clinicians</a>
      <hr />
      </li>}
              <li className="nav-item">
                <a href={PayContants.blogUrl} className="nav-link m-2 menu-item">Blog</a>
                <hr />
              </li>
              <li className="nav-item">
              <a href="/help" className={this.props.category === "help" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>HELP</a>
              <hr />
              </li>
            </ul>
            <div className="book-button "  >
              <button type="button" className=" btn btn-primary rounded-pill">
              <a   onClick={this.toOrder} style={{fontSize: '8px'}}>ORDER NOW</a>
              </button>
            </div>
            <hr />
            {Cookies.get("username")?
            <ul className="main-navbar-nav navbar-nav">

           <li className="nav-item">
           <a href="/logout" className="nav-link m-2 menu-item ">Log out</a>
              <hr />
            </li>
            <br></br>
</ul>:""}
          </div>



      <div id="main" className="bg-white" style={bgwhit}>
      <div className="row">
        <div className="col-6">
          <div className="d-flex  justify-content-start">
            <a  className="navbar-brand  " onClick={this.toHome}><img id="img-1" className="img-size-custom" src="./img/Logo.png" alt="LOGO"/></a>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex justify-content-end">
            <button className="openbtn" onClick={this.toggleMobileMenu}>☰ </button>
            {/* <h2>Collapsed Sidebar</h2> */}
          </div>
        </div>
      </div>

      {this.state.helpmenu ? <div>
        <hr id="hor-line"  style={{ marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)' }} />
        <div id="submenu" className="container"><div className="row  py-md-2">
          <div className="col-12 nav-tab-1 py-md-2">
            <a className="font-nav mr-md-3 mr-sm-1" data-placement="top" href={PayContants.truGenyMailhref} data-toggle="tooltip" data-original-title={PayContants.truGenyMail} style={{ color: 'grey' }} aria-describedby={this.state.hover ? "tooltip367779" : ""}><span>
              <i className="i-button mr-1 fas fa-at" /></span>Email</a><a className="font-nav active nav-active1 mr-md-3 mr-sm-1 " href={PayContants.truGenyHrefConatct} data-toggle="tooltip" data-original-title={PayContants.truGenyConatct} style={{ color: 'rgb(79, 91, 231)' }}><span><i className="i-button mr-1 fas fa-mobile-alt" /></span>{PayContants.truGenyConatct}</a></div></div></div>
      </div> : ""
      }
    </div>

      <header id="headbar" className={(this.state.visible && this.state.desktop) ? "fixed-top bg-white" : ""}>
        <nav id="nav-a-tag" className="main-nav pb-0 navbar navbar-expand-lg nav-menu container" > 
          <a className="navbar-brand  " onClick={this.toHome}><img id="img-1" className="img-size-custom p-0" src="./img/Logo.png" /></a>
          <button className={show ? "navbar-toggler nav-button change" : "navbar-toggler nav-button collapsed"} type="button" data-toggle="collapse" data-target="#myNavbar" aria-expanded="false" onClick={this.toggleMenu}>
            <div className=" line1" />
            <div className=" line2" />
            <div className=" line3" />
          </button>
          <div className={show ? "main-navbar-collapse navbar-collapse text-center justify-content-lg-end text-uppercase collapse show" : "main-navbar-collapse collapse navbar-collapse text-center justify-content-lg-end  text-uppercase"} id="myNavbar" style={{}}>
            <ul className="main-navbar-nav navbar-nav">
              <li className="nav-item">
                <a href="/about-us" className={this.props.category === "about" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>About</a>
              </li>
              <li className="nav-item">
                <a href="/" className={this.props.category === "foryou" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>{Cookies.get("username")?"Discover":"For you"}</a>
              </li>
             
            <li className="nav-item">
              <a href={PayContants.blogUrl} className={this.props.category === "blog" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>Blog</a>
            </li>
            <li className="nav-item">
              <a className={this.props.category === "familyPlanning" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "} href="/family-planning">Family<br/> planning</a>
            </li>
            <li className="nav-item">
              <a className={this.props.category === "genetic-counselling" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "} href="/genetic-counselling">Genetic<br/> counseling</a>
            </li>
              {/* <li class="nav-item">
        <a href="clinicians.html" class="nav-link m-2 menu-item">for clinicians</a>
      </li> */}
              <li className="nav-item">
                <a href="/help" className={this.props.category === "help" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>HELP</a>
              </li>

              {Cookies.get("username")?"":
              <li className="nav-item">
              <a href="/clinicians" className={this.props.category === "clinicians" ? "nav-link m-2 menu-item nav-active" : "nav-link m-2 menu-item "}>For<br/> clinicians</a>
            </li>}
            </ul>

            {Cookies.get("username")? "": <div className="signin-register">
            <ul>
              <li><a style={{ color: showheader === "signin" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "signin" ? 'bold' : '' }} className="signinRegisterBtn" id="signin" href="/login">Sign in</a> <span>|<br/></span> <a className="signinRegisterBtn" id="register" style={{ color: showheader === "register" ? '#4f5be7' : '#2c333c', fontWeight: showheader === "register" ? 'bold' : '' }} href="/register">Register</a></li>
            </ul>
          </div>}


          <div className="book-button "  style={{width: '106px'}}>
              <button type="button" className=" btn btn-primary rounded-pill" >
                <a onClick={this.toOrder}  style={{fontSize: '8px'}}><b>ORDER NOW</b></a>
              </button>
            </div>{Cookies.get("username")?
              <div className={dropdownOpen?"signin-register dropdown  show":"signin-register"} >
              <a  style={{ color:  'green' , fontWeight:  'bold'  }}  className="signin-register" id="signin"   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><b onClick={this.dashBoard}>{Cookies.get('username')}&nbsp;&nbsp;</b><i className="fas fa-chevron-down" onClick={this.toggle}> </i>
              </a>
              <div className={dropdownOpen?"dropdown-menu dropdown-menu-left dropdown-info mt-0 pt-0 show":"dropdown-menu dropdown-menu-left dropdown-info mt-0 pt-0"} aria-labelledby="navbarDropdownMenuLink-4"><a className=" font-weight-bold pt-0 "  id="navbarDropdownMenuLink-4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                </a>

                {/* <a class="dropdown-item pl-4" href="#">Profile</a> */}
                <a className="dropdown-item pl-4 pb-0 pt-3" href="/loginSuccess" >My DashBoard</a>
                <hr className="my-2 py-0"/>
                <a className="dropdown-item pl-4 py-0" href="/logout" >Log out</a>
              </div></div>:""
            }


          </div>
        </nav>
        <div>
        {this.state.submenu ?
          <div onScroll={this.handleScroll}>

            <hr id="hor-line" className={this.state.visible  ? "" : "d-none"} style={{ marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)' }} ></hr>
            <div id="submenu" className={this.state.visible  ? "container" : "container d-none"}>
              <div className="row  py-0">
                <div className="col-9 nav-tab-1">
                  <a className={this.props.category === "foryou" ? "font-nav nav-active1" : "font-nav "} href="/">{Cookies.get("username")?"Discover":"For you"}</a>
                  <a className={this.props.category === "genetic-counselling" ? "font-nav nav-active1" : "font-nav "} href="/genetic-counselling">Genetic Counselling</a>
                  <a className={this.props.category === "cancer" ? "font-nav nav-active1" : "font-nav "} href="/genetic-testing-cancer-risk">Cancer Health</a>
                  <a className={this.props.category === "heart" ? "font-nav nav-active1" : "font-nav "} href="/genetic-testing-heart-disease">Heart Health</a>
                  <a className={this.props.category === "familyPlanning" ? "font-nav nav-active1" : "font-nav "} href="/family-planning">Family Planning</a>
                </div>
                    <div className="col-3 nav-tab-1">
                      <div className="book-button my-0">
                        <button type="button" className="my-0 btn btn-primary rounded-pill" style={{border:'2px solid red'}}>
                          <a href="/help_covid">Coronavirus HELP</a>
                        </button>
                      </div>
                    </div>

                  </div>
                </div>


              </div>

          : ""
        }
        {this.state.helpmenu ? <div>
          <hr id="hor-line"  style={{ marginTop: '.5rem', marginBottom: '.1rem', border: 0, borderTop: '1px solid rgba(0,0,0,.1)' }} />
          <div id="submenu" className="container"><div className="row  py-md-2">
            <div className="col-12 nav-tab-1 py-md-2">
              <a className="font-nav mr-md-3 mr-sm-1" data-placement="top" href={PayContants.truGenyMailhref} data-toggle="tooltip" data-original-title={PayContants.truGenyMail} style={{ color: 'grey' }} aria-describedby={this.state.hover ? "tooltip367779" : ""}><span>
                <i className="i-button mr-1 fas fa-at" /></span>Email</a><a className="font-nav active nav-active1 mr-md-3 mr-sm-1 " href={PayContants.truGenyHrefConatct} data-toggle="tooltip" data-original-title={PayContants.truGenyConatct} style={{ color: 'rgb(79, 91, 231)' }}><span><i className="i-button mr-1 fas fa-mobile-alt" /></span>{PayContants.truGenyConatct}</a></div></div></div>
        </div> 
        
        
        : ""
        }
</div>
      </header>
   {you}
   {this.props.category==="help"?
    <div style={{paddingBottom: '2rem'}} />

   :""}


   {(this.props.category==="genetic-counselling" || this.state.desktop)?<div style={{paddingBottom: '76px'}} className="padding2" />:""



   }

    </div>

    )
  }
}
export default NewMainHeader;
